<template>
    <div class="card">
        <div class="bg-blue-light px-1 rounded pb-2">
            <TitleButton
                class="mt-1"
                btnTitle="List"
                :showBtn="true"
                :showAddNew="false"
                title="Stock Adjustment Details"
                @onClickCloseButton="navigateToListPage"
            />

            <div class="row mt-2 gy-1">
                <div class="row mt-1">
                    <div class="col-sm-4">
                        <label class="align-middle">Warehouse</label>
                        <v-select
                            placeholder="Select Warehouse"
                            v-model="formData.warehouse_id"
                            :options="warehouses"
                            label="text"
                            :reduce="text => text.id"
                        />
                    </div>
                  <div class="col-sm-4">
                    <label for="colFormLabel">Select Project</label>
                    <v-select
                        placeholder="Select Project"
                        :options="projects"
                        label="name"
                        :reduce="name => name.id"
                        v-model="formData.project_id"
                    />
                  </div>
                    <div class="col-sm-4">
                        <label class="align-middle">Adjustment Date</label>
                        <input type="date" class="form-control" v-model="formData.date">
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-md-4">
                        <label class="align-middle">Adjustment Account</label>
                        <v-select
                            placeholder="Select Account Head"
                            v-model="formData.account_head_id"
                            :options="accountHeads"
                            label="name"
                            :reduce="name => name.id"
                        />
                    </div>
                    <div class="col-sm-4"></div>
                    <div class="col-sm-4">
                        <label class="align-middle">Adjustment No</label>
                        <input type="text" class="form-control" v-model="formData.adjustment_no">
                    </div>
                </div>
            </div>
        </div>

        <div class="px-2 mt-2">
            <AddItemDetails
                class="mb-2"
                v-for="(item, index) in formData.item_details"
                :key="index"
                :index="index"
                :item="item"
                :resetAdjQty="false"
                :warehouse-id="formData.warehouse_id"
                @onClose="onClose"
            />
        </div>

        <div class="p-1">
            <div class="row mt-3 px-2">
                <div class="col-12">
                    <div class="mb-2">
                        <label for="note" class="form-label fw-bold">Memo</label>
                        <textarea v-model="formData.description" placeholder="Memo" class="form-control" rows="2"
                                  id="description"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <GlobalLoader />
    </div>
</template>
<script setup>
import {inject, onMounted, ref}  from 'vue'
import {useRoute, useRouter}     from "vue-router"
import TitleButton               from '@/components/atom/TitleButton'
import AddItemDetails            from "@/components/molecule/procurement/inventory/stock/adjustment/AddItemDetails"
import handleStock               from "@/services/modules/stock";
import handleBusinessesLocations from "@/services/modules/businessesLocations";
import handleJournal             from "@/services/modules/journal";
import {useStore}                from "vuex";
import handleProjects from "@/services/modules/procurement/project";

const $router = useRouter()
const $route = useRoute()
const $store = useStore()
const showError = inject('showError')
const showSuccess = inject('showSuccess')
const {updateStockAdjustment, fetchStockAdjustment} = handleStock()
const {fetchBusinessLocationsList} = handleBusinessesLocations();
const {fetchAccountHeads} = handleJournal();
const {fetchProjects} = handleProjects();

let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let warehouses = ref([])
let accountHeads = ref([])
let projects = ref([])
let formData = ref({
    warehouse_id : null,
    project_id : null,
    date : null,
    adjustment_no : null,
    description : null,
    item_details : [],
})

const navigateToListPage = () => {
    $router.push({name: `stock-adjustment-list`, params: $route.params, query: $route.query});
}

const handleSubmit = () => {
    $store.state.loading = true;
    let query = `?company_id=${$route.params.companyId}`;
    let copyFormData = JSON.parse(JSON.stringify(formData.value))
    saveButtonLoader.value = true

    if(!formData.value.date) formData.value.date = new Date().toISOString().split('T')[0]
    copyFormData.item_details = JSON.stringify(formData.value.item_details)
    delete copyFormData.stocks;

    updateStockAdjustment($route.params.adjustmentId, query, copyFormData)
        .then(res => {
            saveButtonLoader.value = false
            saveNewButtonLoader.value = false

            if(res.status) {
                showSuccess(res.message)
                navigateToListPage()
                resetForm()
            }
            if(!res.status) {
                showError(res.message)
            }
        })
        .catch(err=> {
            saveButtonLoader.value = false;
            saveNewButtonLoader.value = false;
            $store.state.loading = false;
            showError(err)
        })
    $store.state.loading = false;
}

const resetForm = () => {
    formData.value = {
        warehouse_id : null,
        project_id : null,
        date : null,
        adjustment_no : null,
        description : null,
        item_details : [
            {
                product_id : null,
                stock_in_hand : 0,
                average_price : 0,
                adjustment_qty : null,
                adjusted_stock : null,
                note : null,
            }
        ],
    }
}
const onClose = (index) => {
    return ;
}

onMounted(() => {
    $store.state.loading = true;
    let query = `?company_id=${$route.params.companyId}`;
    const projectQuery = `${query}&time_and_interval=1`
    formData.value.date = new Date().toISOString().split('T')[0]
    Promise.all([
        fetchBusinessLocationsList(query).then(res => {
            if (res.data) {
                warehouses.value = res.data;
            }
        }),
        fetchAccountHeads($route.params.companyId).then(res => {
            if (res.data) {
                accountHeads.value = res.data;
            }
        }),
        fetchProjects(projectQuery).then(res => {
          if (res.status) {
            projects.value = res.data;
          }
        }),
        fetchStockAdjustment(query, $route.params.adjustmentId).then(res => {
            if (res.data) {
                formData.value = res.data;
                formData.value.item_details = res.data.stocks
                if(res.data.stock_in_hand !== null) {
                    formData.value.item_details[0].stock_in_hand = JSON.parse(res.data.stock_in_hand)[0].stock_in_hand;
                    formData.value.item_details[0].adjusted_stock = JSON.parse(res.data.stock_in_hand)[0].adjusted_stock;
                    formData.value.item_details[0].average_price = JSON.parse(res.data.stock_in_hand)[0].average_price;
                }
            }
        })
    ]);
    $store.state.loading = false;
})
</script>
